import { render, staticRenderFns } from "./DataMaWaterfall.vue?vue&type=template&id=79e70710"
import script from "./DataMaWaterfall.vue?vue&type=script&lang=js"
export * from "./DataMaWaterfall.vue?vue&type=script&lang=js"
import style0 from "./DataMaWaterfall.vue?vue&type=style&index=0&id=79e70710&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports