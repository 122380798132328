<template>
  <v-list-item
      v-if="!header"
      id="user_preferences"
      @click.prevent="clickHandler"
  >
    <v-list-item-icon style="margin-left: 13px; margin-right: 45px !important;">
      <v-icon>mdi-account-cog</v-icon>
    </v-list-item-icon>
    <v-list-item-content style="color: var(--datama-primary);text-align: left;">
      User preferences
    </v-list-item-content>
    <b-tooltip
        placement="bottom"
        boundary="scrollParent"
        :boundary-padding="-30"
        target="user_preferences"
        triggers="hover"
    >
      <div class="text-left">
        Define global preferences for application (language, custom colors etc...)
      </div>
    </b-tooltip>
  </v-list-item>
  <v-btn
      v-else
      v-b-tooltip.hover.bottom="'Edit preferences'"
      class="transparent shadow-none p-0 m-0 text-center"
      :ripple="false"
      style="width: 40px;height: 60px;min-width: unset;"
      @click.prevent="clickHandler"
  >
    <v-icon
        class="d-inline-block text-center"
    >
      mdi-account-cog-outline
    </v-icon>
  </v-btn>
</template>
<script>
export default {
  name: 'PreferenceBtn',
  props: {
    header: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      showToolTip: false
    }
  },
  methods: {
    clickHandler() {
      this.$emit("close")
      this.$store.dispatch('toggleDialog', {id: 'preferences', state: true})
    }
  }
}
</script>